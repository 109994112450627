import React from 'react'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO'
import PageTitle from '@babelcoder/gatsby-theme-base/src/components/PageTitle'
import Pagination from '@babelcoder/gatsby-theme-base/src/components/Pagination'
import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta'
import useAuthorAndPublisherSchema from '@babelcoder/gatsby-theme-base/src/hooks/useAuthorAndPublisherSchema'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'
import ArticleItem from './ArticleItem'
import useArticleSchema from '../../hooks/useArticleSchema'
import useArticlesPath from '../../hooks/useArticlesPath'

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flow-root;
    flex: 1;
    padding: ${theme.spacers.normal};
    background-color: ${theme.colors.neutral.gray100};
  `}
`

const Container = styled.div`
  ${({ theme }) => css`
    max-width: 996px;
    margin: 0 auto;

    ${up('small')} {
      padding: 0 ${theme.spacers.large};
    }
  `}
`

const ArticleList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;

  ${up('large')} {
    grid-auto-rows: 1fr;
  }
`

function getBlogPostSchema(articles, authorAndPublisher, getSchema) {
  return articles.map(
    ({
      node: {
        id,
        excerpt,
        frontmatter: { title, createdAtDate, updatedAtDate, coverImage },
        fields: { slug },
      },
    }) => ({
      ...getSchema({
        id,
        slug,
        excerpt,
        title,
        createdAtDate,
        updatedAtDate,
        coverImage,
      }),
      ...authorAndPublisher,
    })
  )
}

function Blog({ siteName, articles, currentPage, totalPages }) {
  const { siteUrl } = useSiteMeta()
  const { basePath } = useArticlesPath()
  const getSchema = useArticleSchema()
  const authorAndPublisher = useAuthorAndPublisherSchema()
  const title = `บทความทั้งหมดของ ${siteName}`
  const desc = `รวมบทความเกี่ยวกับการโปรแกรมภาษาต่าง ๆ และแนวคิดที่เกี่ยวข้องของ ${siteName}`
  const path = `${basePath}${currentPage === 1 ? '' : `/p/${currentPage}`}`
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Blog',
    name: title,
    description: desc,
    url: toSingleSlash(`${siteUrl}/${path}`),
    sameAs: 'https://facebook.com/babelcoder',
    inLanguage: 'th-TH',
    blogPosts: getBlogPostSchema(articles, authorAndPublisher, getSchema),
  }

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={path}
        desc={desc}
        schema={schema}
      ></SEO>
      <Wrapper>
        <Container>
          <PageTitle>บทความทั้งหมด</PageTitle>
          <ArticleList>
            {articles.map(({ node: article }) => (
              <ArticleItem
                key={article.fields.slug}
                article={article}
              ></ArticleItem>
            ))}
          </ArticleList>
          <Pagination
            basePath={basePath}
            currentPage={currentPage}
            totalPages={totalPages}
          ></Pagination>
        </Container>
      </Wrapper>
    </>
  )
}

export default Blog
