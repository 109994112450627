import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import useBreakpoint from '@babelcoder/gatsby-theme-base/src/hooks/useBreakpoint'
import useArticlesPath from '../../hooks/useArticlesPath'

const StyledArticleItem = styled(Link)`
  ${({ theme }) => css`
    box-shadow: 0px 0px 5px 0px ${theme.colors.neutral.gray400};
    padding: 1rem;

    border-radius: ${theme.round.xlarge};
    background-color: ${theme.colors.neutral.white};
  `}
`

const ArticleContent = styled.article`
  ${up('medium')} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`

const ArticleHeader = styled.header`
  ${up('large')} {
    display: flex;
    justify-content: space-between;
  }
`

const ArticleTitle = styled.h2`
  ${({ theme }) => css`
    flex: 1;
    margin: 0;
    padding-right: ${theme.spacers.normal};
    margin: ${theme.spacers.normal} 0;
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.neutral.gray1200};

    ${up('large')} {
      font-size: ${theme.fontSizes.normal};
      margin: 0;
    }
  `}
`

const ArticleImageWrapper = styled.div`
  ${up('medium')} {
    order: 1;
  }
`

const StyledArticleImage = styled(Img)`
  width: 100%;
  box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.colors.neutral.gray300};
`

const Excerpt = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1100};
    font-size: ${theme.fontSizes.normal};

    ${up('large')} {
      font-size: ${theme.fontSizes.small};
    }
  `}
`

function ArticleImage({ desktopImage, mobileImage }) {
  const size = useBreakpoint()
  const isFixed = size === 'large' || size === 'xlarge'
  const props = isFixed
    ? { fixed: desktopImage.childImageSharp.fixed }
    : { fluid: mobileImage.childImageSharp.fluid }

  return (
    <ArticleImageWrapper as={isFixed ? 'div' : 'span'}>
      <StyledArticleImage {...props}></StyledArticleImage>
    </ArticleImageWrapper>
  )
}

function ArticleItem({
  article: {
    fields: { slug },
    frontmatter: { title, desktopImage, mobileImage },
    excerpt,
  },
}) {
  const { getArticlePath } = useArticlesPath()

  return (
    <StyledArticleItem to={getArticlePath(slug)}>
      <ArticleContent>
        <ArticleHeader>
          <ArticleImage
            desktopImage={desktopImage}
            mobileImage={mobileImage}
          ></ArticleImage>
          <ArticleTitle>{title}</ArticleTitle>
        </ArticleHeader>
        <Excerpt>{excerpt}</Excerpt>
      </ArticleContent>
    </StyledArticleItem>
  )
}

export default ArticleItem
