import React from 'react'
import { graphql } from 'gatsby'

import Blog from '../components/articles/Blog'

const BlogIndex = ({
  data: {
    site: {
      siteMetadata: { siteName },
    },
    allMdx,
  },
  pageContext,
}) => {
  const { edges: articles } = allMdx
  const { currentPage, numPages } = pageContext

  return (
    <Blog
      siteName={siteName}
      articles={articles}
      currentPage={currentPage}
      totalPages={numPages}
    ></Blog>
  )
}

export const pageQuery = graphql`
  query blogIndex($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        siteName
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/content/articles/" } }
      sort: { fields: frontmatter___createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            createdAtDate: createdAt
            updatedAtDate: updatedAt
            coverImage: image {
              childImageSharp {
                fixed(width: 730, height: 415, quality: 80, webpQuality: 80) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            mobileImage: image {
              childImageSharp {
                fluid(maxWidth: 450, quality: 50, webpQuality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            desktopImage: image {
              childImageSharp {
                fixed(width: 150, height: 85, quality: 50, webpQuality: 50) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default BlogIndex
